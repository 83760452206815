import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Providers from "./Providers";
import reportWebVitals from './reportWebVitals';

declare const window: any;
if ("ethereum" in window) {
  (window && window.ethereum as any).autoRefreshOnNetworkChange = false;
}

window.addEventListener("error", () => {
  localStorage?.removeItem("redux_localstorage_simple_lists");
});

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
