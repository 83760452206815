/* eslint-disable no-param-reassign */
import { createReducer } from "@reduxjs/toolkit";
import setChainId from "./actions";

export interface ApplicationState {
  chainId?: number | string | null;
}

const initialState: ApplicationState = {
  chainId: 137,
};

export default createReducer(initialState, (builder) =>
  builder.addCase(setChainId, (state, { payload: { chainId } }) => {
    state.chainId = chainId;
  })
);
