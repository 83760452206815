// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";

import { Router, Route, Switch } from "react-router-dom";
import PageLoader from "./components/PageLoader";
import Menu from "./components/Menu";
import "./App.css";
import history from "./routerHistory";
import { injected } from "./connectors";
import { setupNetwork } from "./utils/wallet";
import Home from "./views/Home";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import Header from "./components/Menu/header";
import Redirect from "./components/RedirectToApp";

function App() {
  const { activate, account } = useWeb3React();
  const { ethereum } = window;

  const defaultConnect = async () => {
    try {
      await activate(injected, async (error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork();
          if (hasSetup) {
            activate(injected);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // window.location.href = "https://app.metalaunch.io/stake";
    // defaultConnect();
    // @ts-ignore
  }, []);

  if (ethereum) {
    ethereum.on("accountsChanged", function (accounts) {
      window.location.reload();
      if (ethereum.removeListener) {
        ethereum.removeListener("chainChanged");
        ethereum.removeListener("accountsChanged");
      }
    });
  }

  useEffect(() => {
    console.warn = () => null;
    if (window && ethereum) {
      ethereum.on("chainChanged", async () => {
        try {
          await activate(injected, async (error) => {
            if (error instanceof UnsupportedChainIdError) {
              const hasSetup = await setupNetwork();
              if (hasSetup) {
                activate(injected);
              }
            }
          });
        } catch (error) {
          console.error(error);
        }
      });
    }
  });

  return (
    <Redirect />
    // <div>
    //   redirecting to{" "}
    //   <a href="https://app.metalaunch.io/stake">
    //     https://app.metalaunch.io/stake
    //   </a>
    // </div>
    // <Router history={history}>
    //   {/* <GlobalStyle /> */}
    //   <Header/>
    //   <Menu />

    //   <SuspenseWithChunkError fallback={<PageLoader />}>
    //     <Switch>
    //       <Route path="/" exact>
    //         <Home />

    //       </Route>
    //     </Switch>
    //   </SuspenseWithChunkError>
    //   {/* <Footer /> */}
    // </Router>
  );
}

export default App;
