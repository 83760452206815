// import { ChainId } from "@cryption-network/polydex-sdk";
import random from "lodash/random";

declare global {
  interface Window {
    web3: any;
    ethereum: any;
  }
}
// Array of available nodes to connect to
export const nodes = {
  "56": ["https://bsc-dataseed.binance.org/"],
  "97": ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
};

const getNodeUrl = () => {
  let chainId = "56";
  if (window && window.ethereum) {
    // @ts-ignore
    chainId = process.env.REACT_APP_CHAIN_ID;
  } else if (localStorage && localStorage.getItem("chainId")) {
    // @ts-ignore
    chainId = localStorage.getItem("chainId");
  }
  // @ts-ignore
  if (nodes[chainId] === null || nodes[chainId] === undefined) {
    // @ts-ignore
    chainId = process.env.REACT_APP_CHAIN_ID;
  }
  // @ts-ignore
  const nodesData = nodes[chainId];
  const randomIndex = random(0, nodesData.length - 1);
  return nodesData[randomIndex];
};

export default getNodeUrl;
