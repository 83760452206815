import React, { useEffect } from "react";

export default function Redirect() {
  useEffect(() => {
    window.location.href = "https://app.metalaunch.io/stake";
    // defaultConnect();
    // @ts-ignore
  }, []);
  return (
    <div>
      {/* redirecting to{" "}
      <a href="https://app.metalaunch.io/stake">
        https://app.metalaunch.io/stake
      </a> */}
    </div>
  );
}
